<template>
    <v-card class="ma-5">
        <v-form v-model="valid" ref="form">
            <v-container>
                <v-row class="my-4 align-center">
                    <h1 class="ms-2">TAREA</h1>
                    <v-spacer></v-spacer>
                    <v-btn @click="volver" color="primary" class="me-2"><v-icon>mdi-arrow-left</v-icon>VOLVER</v-btn>
                    <v-btn v-if="idTarea" @click="del" color="error" class="me-2"><v-icon>mdi-trash-can-outline</v-icon>ELIMINAR</v-btn>
                </v-row>
                <AuxInput class="mx-2 no-gutters" title="DÍA">
                    <v-menu ref="menu" v-model="menu" :return-value.sync="date" transition="scale-transition" offset-y
                        min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="[rules.req]" :value='tarea.fecha != null ? ((new Date(tarea.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(tarea.fecha).getDate()).slice(-2)) + (new Date(tarea.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(tarea.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menu = false" no-title v-model="tarea.fecha" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TAREA">
                    <v-select clearable filled :rules="[rules.req]" v-model="tarea.tipo" dense hide-details :items="tipo" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ESPACIO">
                    <v-combobox multiple chips clearable filled v-model="tarea.espacio" dense hide-details :items="espacio" class="v-superdense-input"></v-combobox>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="HORARIO">
                    <v-select multiple chips clearable :rules="[rules.req]" filled v-model="tarea.horario" dense hide-details :items="horario" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="MEDIOS">
                    <v-select multiple chips clearable filled v-model="tarea.medio" dense hide-details :items="medio" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="MEDIOS ALQUILER" v-if="tarea.tipo == 5">
                    <v-select multiple chips clearable filled v-model="tarea.medioAlq" dense hide-details :items="medio" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EXPLICACIÓN">
                    <v-textarea filled hide-details dense v-model="tarea.explicacion"></v-textarea>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EQUIPO SALA" v-if="tarea.tipo != 5">
                    <v-select multiple chips clearable filled v-model="tarea.equipoMai" dense hide-details :items="equipoMai" :item-text="item => item.cat + ' | ' + item.nombre + ' ' + (item.inicialesApellidos || item.apellidos)" :item-value="item => item.idUnidadEquipo" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EQUIPO BODEGUEROS" v-if="tarea.tipo != 4">
                    <v-select multiple chips clearable filled v-model="tarea.equipoBdg" dense hide-details :items="equipoBdg" :item-text="item => item.cat + ' | ' + item.nombre + ' ' + (item.inicialesApellidos || item.apellidos)" :item-value="item => item.idUnidadEquipo" class="v-superdense-input"></v-select>
                </AuxInput>
                <v-btn color="primary" class="my-2" @click="save"><v-icon>mdi-floppy</v-icon>GUARDAR</v-btn>
            </v-container>
        </v-form>
        <ConfirmDialog ref="confirm" />
    </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";
export default {
    props: {
        idTarea: String,
    },
    components: {
        AuxInput: () => import("@/components/AuxInput.vue"),
        ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    },
    data() {
        return {
            equipoMai: [],
            focus: this.$route.query.focus ? Number(this.$route.query.focus) : null,
            equipoBdg: [],
            tipo:[
                {color:"#FFFF00", val : 1, short: "MONTAJE EVENTO", title:"MONTAJE EVENTO"},
                {color:"#8D32A8", val : 7, short: "PREPARAR MATERIAL" ,title:"PREPARAR MATERIAL"},
                {color:"#DB4D81", val : 8, short: "PREPARAR Y LLEVAR", title:"PREPARAR Y LLEVAR MATERIAL"},
                {color:"#A6C9EC", val : 2, short: "LLEVAR MATERIAL", title:"LLEVAR MATERIAL"},
                {color:"#F1A983", val : 3, short: "RECOGER EVENTO", title:"RECOGER EVENTO"},
                {color:"#E87474", val : 6, short: "ALQUILER",title:"ALQUILER"},
                {color:"#42F5E0", val : 9, short: "ÚLTIMOS DETALLES",title:"ÚLTIMOS DETALLES"},
                {color:"#4AB076", val : 10, short: "REUNIÓN",title:"REUNIÓN"},
                {color:"#E49EDD", val : 4, short: "EVENTO (MAI)", title:"REALIZAR EVENTO (MAI)"},
                {color:"#83E28E", val : 5, short: "EVENTO (BDG)", title:"REALIZAR EVENTO (BDG)"},
            ],
            espacio:[
                "PISCIS",
                "HOTEL",
                "LAUS",
                "LALANNE",
                "MAS DE PONENT",
                "PALACIO",
                "LLOTJA",
                "VDV",
            ],
            horario:[
                { title:"MAÑANA", val : 1},
                { title:"COMIDA", val : 2},
                { title:"TARDE", val : 3},
                { title:"NOCHE", val : 4},
                { title:"TODO EL DÍA", val : 5},
            ],
            medio:[
                {title:"CRAFTER", val : 1},
                {title:"CHASIS", val : 2},
                {title:"JC", val : 3},
                {title:"TRANSP.", val : 4},
                {title:"CADDY", val : 5},
                {title:"ALQ GRANDE", val : 6},
                {title:"ALQ MED", val : 7},
                {title:"ALQ FRÍO", val : 8},
            ],
            rules: { req },
            saving: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            valid: true,
            tarea: {}
        };
    },
    methods:{
        async del(){
            const confirmation =
                (await this.$refs.confirm.open(
                "Eliminar tarea",
                "También se eliminará la tarea. Esta acción NO se puede deshacer.",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                const id = await axios({
                    method: 'DELETE',
                    url: `${process.env.VUE_APP_API_URL}/tareas/${this.idTarea}`,
                })
                this.saving = false;

                this.$root.$emit("snack", "Eliminada correctamente");
                this.$router.push({ name: 'tareas', query:{focus: this.focus}}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se ha podido eliminar correctamente");
            }
        },
        volver(){
            this.$router.push({ name: 'tareas', query:{focus: this.focus}}).catch(()=>{})
        },
        async save(skip) {
            if (!this.$refs.form.validate()) {
                this.$root.$emit("snack", "ERROR: Asegurate de haber rellenado los campos obligatorios");
                return;
            }
            const confirmation =
                skip ||
                (await this.$refs.confirm.open(
                "Guardar los cambios",
                "¿Estás seguro de que quieres guardar este tarea?",
                { color: "primary" }
                ));
            if (!confirmation) return;
            this.$nextTick(async () => {
                try {
                    this.saving = true;
                    const id = await axios({
                            method: 'POST',
                            url: `${process.env.VUE_APP_API_URL}/tareas/nuevo`,
                            data: {
                                datos : this.tarea, 
                                idTarea : this.idTarea
                            }
                        })
                    this.saving = false;
    
                    this.$root.$emit("snack", "Se han guardado los cambios correctamente");
                    this.$router.push({ name: 'tareas',query:{focus: this.focus}}).catch(()=>{})
    
                } catch (err){
                    this.saving = false;
                    console.error(err);
                    this.$root.$emit("snack", "No se han podido guardar los cambios");
                }
            })
		},
        async getTarea() {
            if (this.idTarea){
                try {
                    const { data } = await axios({
                        method: 'GET',
                        url: `${process.env.VUE_APP_API_URL}/tareas/${this.idTarea}`,
                    })
                    this.tarea = data;
                    this.tarea.espacio = JSON.parse(this.tarea.espacio.replace(/'/g, '"'))
                    this.tarea.medio = JSON.parse(this.tarea.medio.replace(/'/g, '"'))
                    this.tarea.horario = JSON.parse(this.tarea.horario.replace(/'/g, '"'))
                    this.tarea.equipoMai = JSON.parse(this.tarea.equipoMai.replace(/'/g, '"'))
                    this.tarea.equipoBdg = JSON.parse(this.tarea.equipoBdg.replace(/'/g, '"'))
                    if (!this.tarea.fecha) this.tarea.fecha = new Date();
                } catch (err){
                    this.saving = false;
                    console.error(err);
                    this.$root.$emit("snack", "No se han podido cargar los datos de esta tarea");
                }
            }
            else {
                this.tarea.fecha = new Date().toISOString().split('T')[0];
            }
        },
        async getEquipo() {
            try {
                const { data } = await axios({
                    method: 'GET',
                    url: `${process.env.VUE_APP_API_URL}/unidades_equipo/montajes`,
                })
                this.equipoMai = data.equipoMai;
                this.equipoBdg = data.equipoBdg;
            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se ha podido cargar el listado del equipo");
            }
        }
    },
    async mounted(){
        await Promise.all([
            this.getEquipo(),
            this.getTarea()
        ])
    }
};
</script>